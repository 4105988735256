import request from '../utils/request';
//获取全部校区或某个校区的班级
export const selectSportsSchoolAndClass = () => {
    return request({
        url: '/sys/sportTest/selectSportsSchoolAndClass',
        method: 'POST',
        data: {"schoolName":""}
    });
};
//获取全部体测项ID和名称
export const selectProjectIdAndName = () => {
    return request({
        url: '/sys/sportTest/selectProjectIdAndName',
        method: 'get',
    });
};
//获取单个体测学员的按评分标准测算后的成绩
export const loadOneSportTestResult = (scannedBarcode) => {
    return request({
        url: '/sys/sportTest/loadOneSportTestResult',
        method: 'POST',
        data: {"studentIdentity":scannedBarcode}
    });
};
//获取某校区 某几个班级的 体测成绩进度
export const loadSportTestByMultiClassNameShow = (query) => {
    return request({
        url: '/sys/sportTest/loadSportTestByMultiClassNameShow',
        method: 'POST',
        data:query
    });
};  
//获取某校区体测的基础进度
export const loadSportTestShow = (query) => {
    return request({
        url: `/sys/sportTest/loadSportTestShow?schoolName=${query}`,
        method: 'get',
    });
};