<template>
    <div>

        <el-dialog title="选择学校"  :close-on-click-modal="false"
        :close-on-press-escape="false"  v-model="dialogVisible">
            <div style="display:flex;justify-content: center;align-items: center;">
                <div style="margin:0 0.6rem">
                    选择本次看板数据学校：
                </div>
                <div style="margin:0 0.6rem">
                <el-select v-model="query.schoolName" placeholder="请选择学校" >
                    <el-option v-for="(item,index) in schoolList" :key="index" :label="item.school" :value="item.school"></el-option>
                </el-select>    
                </div>
            </div>
        <template #footer>      
            <el-button type="primary" @click="confirmSchool">确 定</el-button>
        </template>
      </el-dialog>
      <dv-full-screen-container class="box" v-show="!dialogVisible">
        <div style="position:absolute;top:4%;left:1%" class="rotating-element">
            <img src="https://codeimg.yanhuotiyu.com/SportsKeCengCredentials/2024/4/9/20240409154351lixin0.png" alt="" style="width:46px">
        </div>
         <div style="position:absolute;bottom:2%;right:1%" class="rotating-element">
            <img src="https://codeimg.yanhuotiyu.com/SportsKeCengCredentials/2024/4/9/20240409154351lixin0.png" alt=""  style="width:46px">
        </div>
        <div style="position:absolute;top:4%;right:1%" class="rotating-element">
            <img src="https://codeimg.yanhuotiyu.com/SportsKeCengCredentials/2024/4/9/20240409154351lixin0.png" alt=""  style="width:46px">
        </div>
        <div style="position:absolute;bottom:2%;left:1%" class="rotating-element">
            <img src="https://codeimg.yanhuotiyu.com/SportsKeCengCredentials/2024/4/9/20240409154351lixin0.png" alt=""  style="width:46px">
        </div>
        <dv-border-box-11 title="焱火体测数据看板" style="font-weight:bold;">
            <div style="height:1.25rem;"></div>             
            <div>
                <!-- 学校名称、测试时间、基本信息。。。 -->
                <div style="display:flex;justify-content: space-around;">
                    <dv-border-box-1 class="box2">
                        <div style="color:#fff;display: flex;justify-content: space-around;margin-top: 1.5rem;font-size: 2rem;">
                            <div>测试学校-时间：{{query.schoolName}}</div>
                        </div>
                        <!-- 进度 -->
                        <div>    
                            <div v-if="!loading3" style="display: flex;justify-content: space-around;" >
                                <div v-for="(item,index) in testProjectArr" :key="index" style="color:#fff;font-weight: bold;font-size: 1.3rem;width: 2.5rem;text-align:center">
                                    <div> {{item.sportsProjectName}}</div>
                                    <div> {{item.testCount}} </div>
                                </div>
                            </div>          
                            <div style="display:flex;justify-content: center;align-items: center;height:10rem;" v-if="loading3" >
                                <dv-loading style="color:#fff" >Loading...</dv-loading>
                            </div>
                        </div>
                    </dv-border-box-1>  
                    <dv-border-box-1 class="box2" >
                        <div v-if="this.config2.data" style="color:#fff;display: flex;justify-content: space-around;margin-top: 1.5rem;font-size: 2rem;">
                            满分榜（满分人数{{this.config2.data.length}}）
                        </div> 
                        <div>
                            <dv-scroll-board v-if="!loading2" :config='config2'  style="width: 50rem;;height:15vh;color: #fff;margin:1.25rem 0 0 1.25rem;" />
                            <div style="display:flex;justify-content: center;align-items: center;height:18vh;" v-if="loading2">
                                <dv-loading style="color:#fff" >Loading...</dv-loading>
                            </div>
                        </div>
                    </dv-border-box-1>        
                </div>
                <!-- 各个班级成绩展示 -->         
                <div style="display:flex;justify-content: center;flex-wrap: wrap;">
                    <dv-border-box-1 class="box1" >
                        <div style="color:#fff;text-align: center;font-size: 2rem;">   班级体测成绩展示    </div>
                        <dv-scroll-board v-if="!loading1" :config='config1'  style="width: 98%;;height:40vh;color: #fff;margin:1.25rem 0 0 1.25rem;" />
                        <dv-loading v-if="loading1" style="color:#fff">Loading...</dv-loading>
                    </dv-border-box-1>              
                </div>
                <div style="display: flex;justify-content:space-around;margin-top: 1.25rem;align-items: center;font-weight: bold;font-size: 3rem;color:red">
                    <div style="width:25%;text-align:center;padding-left: 3rem;">
                        <div style="text-align: center;">
                            <img src="https://codeimg.yanhuotiyu.com/SportsKeCengCredentials/2024/4/9/20240409154351lixin0.png" alt="">
                        </div>           
                        <div>四个火，提升快</div>
                    </div>
                    <div style="text-align:left;width:60%;">
                        <!-- <div><img src="https://codeimg.yanhuotiyu.com/SportsKeCengCredentials/2024/4/9/20240409154351lixin0.png" alt="" style="visibility: hidden;"></div>   -->
                        <div style="font-size:6rem;padding-left: 10rem;">精准测量，公正评判</div> 
                    </div>  
                </div>
                  
            </div>
          <!-- 小图表的外边框组件 -->
        </dv-border-box-11>
      </dv-full-screen-container>
      <!-- 查看单个学员体测的成绩 弹框 -->
      <el-dialog title="体测成绩" v-model="oneSportsTestStudentDialog" width="600px" center>
        <div class="transcript">
            <div style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);border-radius: 25px;padding: 20px;width: 72%;margin-left:10%;font-weight: bold;">
                <div style="display:flex;justify-content: space-between;margin: 10px 0;">
                    <div>学生学号:{{ oneRowData.studentIdentity }}</div>
                    <div>学生学校:{{ oneRowData.className }}</div>
                </div>
                <div style="display:flex;justify-content: space-between;margin: 10px 0;">
                    <div>
                        学生姓名 {{ oneRowData.name }}
                    </div>
                    <div>
                       学生性别：{{ oneRowData.sex }}
                    </div>
                </div>
                <div style="display:flex;justify-content: space-between;">
                    <div>所在班级:{{ oneRowData.className }}</div>
                </div>
                <el-table style="margin-top: 40px;font-size: 20px;font-weight: bold;"
                      :data="oneRowData.testResultsVOS" :row-style="{height:'2px'}">
                <el-table-column prop="sportsProjectName" label="项目"/>
                <el-table-column prop="score" label="成绩"/>
                <el-table-column prop="results" label="得分"/>
                </el-table>
                <div style="font-size:26px;margin:10px 0;text-align:center">
                    总分:<span style="color:red">{{ testCount }}</span>!!!
                </div>
            </div>
        </div>
        <div style="text-align:center">
            <audio autoplay="autoplay"
                controls="controls" controlsList="nodownload noplaybackrate"
                preload="auto"
                :src="oneRowData.happyWavPath">
            </audio>
        </div>       
        <div style="display: flex;justify-content: center;padding-top: 30px">
          <el-button :size="small" type="primary" @click="oneSportsTestStudentDialog=false">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
   
  <script>
  import {selectSportsSchoolAndClass,loadSportTestShow, loadSportTestByMultiClassNameShow,loadOneSportTestResult
    // selectProjectIdAndName
} from '../../api/physicalTest'
  export default {
    data(){ 
        return{
            loading3:false,
            config3:{},
            loading1:true,
            loading2:true,
            page:0,
            query:{ 
                schoolName:'',
                classNameList: [],
                onlyFullResult:0
            },
            scannedBarcode:"",
            oneRowData:{},
            oneSportsTestStudentDialog:false,
            dialogVisible:true,
            schoolList:[],
            header: ['编号','班级','姓名','性别'],
            config1:{},
            config2:{},
            data2:[],
            header2: ['编号','班级','姓名','性别','总分'],
            timer:{},
            calssArr:[],
            todayStudentCount:null,
            testProjectArr:[],
            testCount:0,
            timer3:{},
        }
    },
    created(){
        selectSportsSchoolAndClass().then(res=>{
            if(res.code===200){ 
                this.schoolList=res.data
            }else{ 
                this.$message.error('获取学校列表失败！')
            }
        })
    },
    methods:{
      /**
       *
       * @param event
       */
      handleKeydown(event) {
        // 如果是回车键或其他结束字符，则将缓冲区的内容作为条码值处理
        if (event.key === 'Enter' || event.key === ' ') {
         this.oneSportsTestStudentDialog = false;
          this.loadOneSportTestResult(); 
        } else {
          this.scannedBarcode += event.key;
        }
      },
      /**
       * 获取单个体测学员的按评分标准测算后的成绩
       */
      loadOneSportTestResult(){
        this.testCount = 0
        loadOneSportTestResult(this.scannedBarcode).then(val => {
          this.scannedBarcode = "";
          if (val.code == 200){
            this.oneSportsTestStudentDialog = true;
            this.oneRowData = val.data;
            if(val.data.testResultsVOS){ 
                val.data.testResultsVOS.forEach(e=>{ 
                    this.testCount+=e.results
                }) 
            }  
          }
        })
      },
      async confirmSchool(){
            if(!this.query.schoolName){ 
                this.$message.error('请选择学校！')
            }else{
                const checkSchoolIndex = this.schoolList.findIndex(obj => obj.school === this.query.schoolName );
                //学校所有班级
                this.calssArr =  this.schoolList[checkSchoolIndex].classes
                await loadSportTestShow(this.query.schoolName).then(res=>{
                    if(res.code===200){ 
                        res.data.sportTypeTestCount.forEach(item=>{
                            this.header.push(item.sportsProjectName)
                            this.testProjectArr.push(item)
                        })
                        this.timer2 = setInterval(()=>{ 
                            this.repetitionRequest1()
                        },60000)
                        this.todayStudentCount = res.data.todayStudentCount
                        
                    }else{ 
                        this.$message.error('获取学校体测的基础进度失败！')
                    }
                })
                this.query.classNameList.push(this.calssArr[this.page].school) 
                this.page ++
                loadSportTestByMultiClassNameShow(this.query).then(res=>{ 
                    if(res.code == 200){ 
                        this.dialogVisible = false
                        this.header.push('总分')
                        this.config1 = {
                            header: this.header,
                            data: res.data.studentScoreResultList,
                            columnWidth: [60],
                            align: ['center'],
                            rowNum:7,
                            headerBGC: '#0f1325',
                            oddRowBGC: '#0f1325',
                            evenRowBGC: '#171c33',
                            carousel: 'page',
                            waitTime:10000
                        }
                        this.loading1 = false
                        this.timer = setInterval(() => {
                            if(this.page >= this.calssArr.length){ 
                                this.page = 0
                            }
                            // console.log("定时器回调，执行中，等待。。。")
                            this.repetitionRequest()
                        },60000)
                    }else{ 
                        this.$message.error('获取学校体测的基础进度失败！')
                    }
                })
                // this.dialogVisible = false
                this.serachfullMark()
                
            }
        },
        repetitionRequest() {
            this.loading1 = true
              this.query.classNameList = []
              this.config1 = {}
            //   console.log('当前班级页码',this.page);
              this.query.classNameList.push(this.calssArr[this.page].school)
              this.page++
            //   console.log('当前班级页码12312312312321',this.page);
              loadSportTestByMultiClassNameShow(this.query).then(res => {
                  if (res.code == 200) {
                      this.config1 = {
                          header: this.header,
                          data: res.data.studentScoreResultList,
                          columnWidth: [60],
                          align: ['center'],
                          rowNum: 7,
                          headerBGC: '#0f1325',
                          oddRowBGC: '#0f1325',
                          evenRowBGC: '#171c33',
                          carousel: 'page',
                          waitTime:10000
                      }
                    this.loading1 = false     
                  } else {
                    this.loading1 = false     
                      this.$message.error('获取学校体测的基础进度失败！')
                  }
              })
        },
        repetitionRequest1(){
            this.testProjectArr = []
            this.loading3 = true
            loadSportTestShow(this.query.schoolName).then(res=>{
                if(res.code===200){ 
                        res.data.sportTypeTestCount.forEach(item=>{                   
                            this.testProjectArr.push(item)
                        })    
                        this.loading3  = false
                }else{ 
                        this.$message.error('获取学校体测的基础进度失败！')
                }
            })
        },
        serachfullMark(){
            let parma = {
                schoolName:this.query.schoolName,
                classNameList: [],
                onlyFullResult:1
            }
            this.loading2 = true
            loadSportTestByMultiClassNameShow(parma).then(res => {
                if (res.code == 200) {
                    let arr = []
                    res.data.studentScoreResultList.forEach(item => {
                        arr.push(item[0]),  
                        arr.push(item[1])
                        arr.push(item[2])  
                        arr.push(item[3])
                        arr.push(item[item.length-1])
                        this.data2.push(arr) //爱你么么叽
                        arr = []
                    })
                    this.config2 = {
                          header: this.header2,
                          data: this.data2,
                          columnWidth: [60],
                          align: ['center'],
                          rowNum: 4,
                          headerBGC: '#0f1325',
                          oddRowBGC: '#0f1325',
                          evenRowBGC: '#171c33',
                          carousel: 'page',
                          waitTime:10000
                      }
                      this.loading2 = false
                      this.timer3 = setInterval(()=>{ 
                        this.repetitionRequest2()
                      },120000)
                }else{ 
                    this.loading2 = false
                    this.$message.error('满分榜学生获取失败！')
                }
                    
            })
        },
        repetitionRequest2(){
            this.data2 = []
            this.config2 = {}
            let parma = {
                schoolName:this.query.schoolName,
                classNameList: [],
                onlyFullResult:1
            }
            this.loading2 = true
            loadSportTestByMultiClassNameShow(parma).then(res => {
                if (res.code == 200) {
                    let arr = []
                    res.data.studentScoreResultList.forEach(item => {
                        arr.push(item[0]),  
                        arr.push(item[1])
                        arr.push(item[2])  
                        arr.push(item[3])
                        arr.push(item[item.length-1])
                        this.data2.push(arr) //爱你么么叽
                        arr = []
                    })
                    this.config2 = {
                          header: this.header2,
                          data: this.data2,
                          columnWidth: [60],
                          align: ['center'],
                          rowNum: 4,
                          headerBGC: '#0f1325',
                          oddRowBGC: '#0f1325',
                          evenRowBGC: '#171c33',
                          carousel: 'page',
                          waitTime:10000
                      }
                      this.loading2 = false
                }else{ 
                    this.loading2 = false
                    this.$message.error('满分榜学生获取失败！')
                }
                    
            })
        },  
    },

    beforeUnmount(){
        clearInterval(this.timer)
        clearInterval(this.timer2)
        clearInterval(this.timer3)
    },
    mounted(){
      window.addEventListener('keydown', this.handleKeydown);     
    },
  }

  </script>
   
  <style>
  /* 根据具体的情况对图表进整体的布局以及css的修改 */
  .box {
    background: rgb(12, 30, 52);
  }
  .box1{
    /* margin-left:1.25rem; */
    padding-top:2.5rem;
    height:48vh;
    width:99%;
  }
  .box2{
    /* margin-left:1.25rem; */
    padding-top:2.5rem;
    height:25vh;
    width:53rem
  }
  @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }

  .rotating-element {
    animation: spin 5s linear infinite;
  }

  </style>